import app from "firebase/app"
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
// import config from '../firebaseConfig'
import config from '../config'

// 
class Firebase{
  constructor(){

    app.initializeApp(config)
    this.db = app.firestore()
    this.auth = app.auth()
    this.analytics = app.analytics()
    

    this.uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        app.auth.EmailAuthProvider.PROVIDER_ID,
        app.auth.GoogleAuthProvider.PROVIDER_ID
        
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
      }
    }
  }

  async register(firstname, lastname, email, password) {
    const newUser = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    )
    await newUser.user.updateProfile({
      displayName: firstname
    })
    const dbUser = {
      uid: newUser.user.uid,
      firstname: firstname,
      lastname: lastname,
      email: newUser.user.email,
      isDoctor: false
    }
    // this.db.collection('user').add(dbUser)

    this.db.collection('user').doc(dbUser.uid).set(dbUser)
    // return newUser
    return dbUser
  } 

  async login(email, password){
    // return await this.auth.signInWithEmailAndPassword(email, password)
    const user = await this.auth.signInWithEmailAndPassword(email, password)
  
    let userRef = this.db.collection('user').doc(user.user.uid)
    
    let dbUser = await userRef.get().then(function (doc) {
      if(doc.exists){
        return doc.data()
      }
    })
    return dbUser
  }


  async logout(){
    await this.auth.signOut()
    // return this.auth.currentUser
  }
}
  // async function register(name, email, password) {
  //   const newUser = await auth.createUserWithEmailAndPassword(
  //     email,
  //     password
  //   )

  //   return await newUser.user.updateProfile({
  //     displayName: name
  //   })
  // } 

  // async function login(email, password){
  //   return await auth.signInWithEmailAndPassword(email, password)
  // }

  // return(

  // )



export default Firebase