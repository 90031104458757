/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/global.css"
import "./src/styles/mobilenav.css"
import './node_modules/materialize-css/dist/js/materialize.js'
import './node_modules/materialize-css/dist/css/materialize.css'
// import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
// import './node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css'

import React from "react";
import Firebase, {FirebaseContext} from './src/firebase'
import AuthProvider from './src/Auth/authProvider';

export const wrapRootElement = ({element}) => {
  return(
  <FirebaseContext.Provider value={new Firebase()}>
    <AuthProvider>
      {element}
    </AuthProvider>
  </FirebaseContext.Provider>
  )
}